import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import Layout from "../components/layout";
import ClientOnly from "../clientOnly";

const useStyles = makeStyles((teme) => ({
  text: {
    margin: "6.8vw 0 1.66vw 2.22vw",
    "@media(min-width: 1440px)": {
      margin: "98px 0 24px 32px",
    },
    "@media(max-width: 1024px)": {
      margin: "9.57vw 0 2.34vw 2.63vw",
    },
    "@media(max-width: 767px)": {
      margin: "25.12vw 0 6.15vw 8.2vw",
    },
  },
}));

function ErrorPage() {
  const classes = useStyles();
  
  return (
    <ClientOnly>
      <Layout>
        <Typography className={classes.text}>PAGE 404</Typography>
      </Layout>
    </ClientOnly>
  );
}

/**
 * Страница ошибки
 * @module src/pages/404
 */
export default ErrorPage;
